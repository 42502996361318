@import "../../../dependencies/smart-grid";
@include reset;

$mainFont: 'Roboto', sans-serif;
$condFont: 'Roboto Condensed', sans-serif;
$orange: #fc8200;
$white: #fff;
$black: #000;

@mixin orange-line(){
  display: block;
  content: '';
  position: absolute;
  width: 117px;
  bottom: 80px;
  @include sm(bottom, 60px);
  left: 0;
  height: 9px;
  background-color: $orange;
}
@keyframes bg-image {
  0%{
    background-size: 100%;
  }
  50%{
    background-size: 110%;
  }
  100%{
    background-size: 100%;
  }
}
@keyframes blink {
  0%{
    transform: scale(1,1);
  }
  50%{
    transform: scale(1.5,1.5);
  }
  100%{
    transform: scale(1,1);
  }
}
@keyframes circleRotate {
  0%{
    transform: rotate(0deg);
  }
  50%{
    transform: rotate(360deg);
  }
  100%{
    transform: rotate(0deg);
  }
}
@keyframes circleRotate2 {
  0%{
    transform: rotate(0deg);
  }
  50%{
    transform: rotate(-360deg);
  }
  100%{
    transform: rotate(0deg);
  }
}

h1,h2,h3,h4,h5,h6{
  color: $white;
  text-transform: uppercase;
}
h1{
  font-size: 3.8rem;
  font-weight: 800;
  position: relative;
}
h3{
  font-size: 1.5rem;
  text-transform: none;
}
h4{
  font-size: 1rem;
}
a{
  color: black;
  text-decoration: none;
  text-transform: uppercase;
}

.container{
  @include wrapper;
}
.row{
  @include row-flex;
}

body,html{
  font-family: $mainFont;
  font-size: 14px;
  overflow: hidden;
  @include sm(overflow, visible);
  background-color: #000;
}
li[class*='screen']{
  opacity: 0;
  position: absolute;
  @include sm(opacity, 1);
  @include sm(position, relative);
  transition: all .2s linear;
  &.active{
    opacity: 1;
  }
}

.first-screen,
.second-screen,
.third-screen,
.fourth-screen{
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.fourth-screen{
  min-height: 100%;
  height: auto;
}

.fa.fa-angle-double-right,
.fa.fa-angle-double-left{
  @include sm(display, none !important);
  position: fixed;
  z-index: 2;
  color: rgba($orange,.3);
  top: 45%;
  cursor: pointer;
  transition: all .2s linear;
  &:hover{
    transform: scale(1.1,1.1);
  }
}
.fa.fa-angle-double-right{
  right: 4px;
}
.fa.fa-angle-double-left{
  display: none;
}

._orange-text{
  color: rgba($orange,.99);
  background: url(../img/spray.png);
  background-clip: text;
  -webkit-background-clip: text;
}
._button{
  display: inline-block;
  background-color: $orange;
  border: 2px solid $orange;
  padding: 10px 50px;
  @include sm(padding, 10px 25px);
  border-radius: 50px;
  margin: 25px 0;
  color: $white;
  font-size: 1.1rem;
  font-weight: 600;
  transition: 300ms ease;
  cursor: pointer;
  &:hover{
    box-shadow: 0 0 7px rgba(0,0,0,.6);
    background-color: $orange;
    color: $white;
  }
}
._nickname{
  color: $orange;
}
._extra{
  color: $orange;
}

.header{
  position: fixed;
  width: 100%;
  z-index: 9999;
  background-color: rgba(0,0,0,.2);
  .row{
    justify-content: space-between;
    align-items: center;
  }
  &-nav{
    margin: 0 15px;
    ul{
      text-align: right;
      li{
        display: inline-block;
        @include sm(margin, 10px 15px);
        @include sm(display, none);
        &:nth-child(1){
          @include sm(display, inline-block);
        }
        &:last-child{
          @include sm(display, inline-block);
        }
        a{
          padding: 0 10px;
          color: #b8baba;
          transition: 200ms ease;
          &:hover{
            color: $orange;
          }
          i{
            transform: scale(1.5,1.5);
          }
        }
        &.menu{
          margin-left: 25px;
          cursor: pointer;
          a{
            color: $orange;
            &:hover{
              color: rgba($white,.7);
            }
          }
        }
      }
    }
  }
  &-logo{
    img{
      @include sm(height, 50px);
    }
    margin: 0 15px;
    cursor: pointer;
  }
}
.opening{
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 1;
  overflow: hidden;
  .row{
    height: 100vh;
    @include sm(height, auto);
    @include sm(min-height, 100vh);
    align-items: center;
  }
  &-video{
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -1000;
    overflow: hidden;
  }
  &-text{
    flex-basis: 45%;
    @include sm(flex-basis, 100%);
    align-items: center;
    margin: 0 10px;
    h1{
      margin: 130px 0 50px 0;
      @include sm(font-size, 24px);
      &:before{
        @include orange-line;
      }
    }
  }
}
.heroes{
  width: 100%;
  height: 100%;
  @include sm(height, auto);
  background-position: center center;
  @include sm(background-position, 70% center);
  background-size: 100%;
  @include sm(background-size, cover);
  transition-property: background-size;
  animation: bg-image 20s linear infinite;
  @include sm(animation, none);
  position: relative;
  overflow: hidden;
  .container{
    position: relative;
    height: 100%;
  }
  .row{
    height: 100%;
    overflow: hidden;
    margin: 0 0;
  }
  &-header{
    height: 70px;
    flex-basis: 100%;
    margin-top: 130px;
    z-index: 2;
    h1{
      font-size: 2.8rem;
      @include sm(font-size, 24px);
    }
    h1:before{
      @include orange-line;
    }
  }
  &-info{
    @include sm(flex-basis, 100%);
    flex-basis: 40%;
    height: 500px;
    z-index: 2;
    position: relative;
    &>div{
      position: relative;
    }
    &-icon{
      margin-left: 15px;
      cursor: pointer;
    }
    &-chevrons{
      @include sm(display, none !important);
      position: absolute;
      display: flex;
      justify-content: space-between;
      top: 9px;
      left: 0;
      color: $orange;
      width: 100%;
      height: 5px;
      z-index: 2;
      i{
        opacity: .5;
        cursor: pointer;
        transition: all .2s linear;
        &:hover {
          opacity: 1;
        }
      }
    }
    &-slider{
      color: $orange;
      position: relative;
      margin: 3vh 0;
      padding-left: 30px;
      @include sm(padding, 0);
      @include sm(display, flex);
      @include sm(justify-content, space-between);
      i{
        position: relative;
        top: -20px;
      }
      &-item{
        display: inline-block;
        width: 50px;
        height: 50px;
        @include sm(width, 28px);
        @include sm(height, 28px);
        background-position: center center;
        background-size: cover;
        border-radius: 50%;
        margin: 0 10px;
        @include sm(margin, 0);
        cursor: pointer;
        &.active{
          position: relative;
          transform: scale(1.5,1.5);
          &:before{
            position: absolute;
            display: block;
            content: '';
            width: calc(6px + 100%);
            height: calc(6px + 100%);
            border-radius: 50%;
            border: 3px solid $orange;
            top: -6px;
            left: -6px;
            background-color: transparent;
            z-index: -1;
          }
        }
      }
      &:after{
        position: absolute;
        display: block;
        content: '';
        width: 85%;
        height: 3px;
        top: 23px;
        @include sm(top, 14px);
        @include sm(left, 28px);
        left: 34px;
        background-color: $orange;
        z-index: -1;
      }
    }
    &-name{
      display: inline-block;
      text-transform: uppercase;
      margin-bottom: 10px;
      position: relative;
    }
    &-type{
      margin-bottom: 10px;
    }
    &-text{
      text-transform: none;
      line-height: 1.5rem;
    }
    hr{
      border: none;
      height: 1px;
      background-color: #4a4a4b;
    }
    &-sub{
      color: $white;
      font-size: 12px;
    }
    &-camera{
      margin-left: 15px;
      cursor: pointer;
      transition: all .2s ease-in-out;
      &:hover {
        box-shadow: -5px 5px 5px rgba(0,0,0,.4);
        transform: rotate(5deg) scale(1.05);
      }
    }
    &-button{
      margin-top: 20px;
      padding: 10px 50px;
    }
    &-alt{
      margin-left: 15px;
      font-size: 14px;
      color: $orange;
      opacity: 0;
      transition: all .2s linear;
      &.show{
        opacity: 1;
      }
    }
  }
  &-main{
    @include sm(display, none);
    flex-basis: calc(60% - 30px);
    z-index: 2;
    margin: 0 15px;
    position: relative;
    &-block{
      position: relative;
      &-item{
        position: absolute;
        top: -150px;
        left: 1000px;
        z-index: -1;
        img{
          width: 600px;
        }
        &-dot{
          position: absolute;
          display: block;
          width: 14px;
          height: 14px;
          z-index: 1;
          cursor: pointer;
          border-radius: 50%;
          background-color: $orange;
          &:after{
            display: block;
            content: '';
            position: absolute;
            width: calc(100% + 6px);
            height: calc(100% + 6px);
            border-radius: 50%;
            top: -3px;
            left: -3px;
            background-color: rgba($orange,.4);
          }
          &:hover{
            animation: blink 1s linear infinite;
          }
        }

        &[data-heroes='3'] {
          span:nth-child(1){
            top: 170px;
            left: 180px;
          }
          span:nth-child(2){
            top: 210px;
            left: 320px;
          }
          span:nth-child(3){
            top: 430px;
            left: 230px;
          }
        }

        &[data-heroes='2'] {
          span:nth-child(1){
            top: 150px;
            left: 240px;
          }
        }

        &[data-heroes='1'] {
          span:nth-child(1){
            top: 190px;
            left: 350px;
          }
          span:nth-child(2){
            top: 250px;
            left: 300px;
          }
        }

        &[data-heroes='4'] {
          span:nth-child(1){
            top: 170px;
            left: 140px;
          }
        }

        &[data-heroes='5'] {
          span:nth-child(1){
            top: 270px;
            left: 270px;
          }
          span:nth-child(2){
            top: 240px;
            left: 150px;
          }
        }

      }
    }
    &-ammunition{
      @include col;
      @include col-10;
      @include offset-left-padding(2);
      @include offset-left(2);
      position: relative;
      top: 30%;
      padding-top: 35px;
      padding-bottom: 35px;
      padding-right: 20px;
      color: $white;
      background-color: rgba($black,.7);
      z-index: 999;
      h2{
        text-transform: none;
        fotn-size: 24px;
      }
      p{
        font-size: 16px;
      }
      &-img{
        width: 182px;
        height: 182px;
        border-radius: 50%;
        position: absolute;
        top: -50%;
        left: -25%;
        background-size: cover;
        background-position: center center;
        z-index: 2;
        &:after{
          position: absolute;
          display: block;
          content: '';
          border: 1px solid $orange;
          width: calc(100% + 20px);
          height: calc(100% + 20px);
          top: -11px;
          left: -11px;
          border-radius: 50%;
        }
      }
      &:after{
        position: absolute;
        display: block;
        content: '';
        border: 1px solid $orange;
        top: 10px;
        left: 10px;
        height: calc(100% - 20px);
        width: calc(100% - 20px);
        z-index: 1;
      }
    }
  }
  &:after{
    position: absolute;
    display: block;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.6);
    z-index: 1;
  }
  &-order{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: rgba($black,.6);
    &-show{
      @include col;
      width: 580px;
      @include sm(width, 95%);
      color: $white;
      position: fixed;
      top: calc(50% - 200px);
      left: calc(50% - 290px);
      @include sm(top, 0);
      @include sm(left, 0);
      @include sm(box-sizing, border-box);
      padding: 32px 77px;
      @include sm(padding, 32px 30px 32px 50px);
      background-color: rgba($black,.8);
      i{
        position: absolute;
        top: -30px;
        right: -30px;
        @include sm(top, 15px);
        @include sm(right, 15px);
        cursor: pointer;
      }
      h2{
        position: relative;
        font-size: 24px;
        text-transform: none;
        &:before{
          display: block;
          content: '';
          position: absolute;
          top: -20%;
          left: -45px;
          @include sm(left, -25px);
          @include sm(top, 0);
          width: 2px;
          height: 140%;
          background-color: $orange;
        }
      }
      &-item{
        margin: 35px 0;
        float: left;
        width: 50%;
        @include sm(width, 100%);
        @include sm(margin, 5px 0);
        @include sm(float, none);
        input{
          background-color: transparent;
          border: none;
          border-bottom: 2px solid $orange;
          font-style: italic;
          padding: 17px 0 17px 17px;
          width: 80%;
          outline: none;
          color: $white;
        }
      }
      &-submit{
        display: block;
        margin: 0 auto;
        @include sm(margin-top, 15px);
        text-transform: uppercase;
      }
      &:after{
        display: block;
        content: '';
        position: absolute;
        top: 10px;
        left: 10px;
        height: calc(100% - 20px);
        width: calc(100% - 20px);
        border: 1px solid $orange;
        z-index: -1;
      }
      a{
        display: block;
        text-transform: none;
        color: $orange;
        padding-top: 10px;
      }
      &-close {
        position: absolute;
        width: 30px;
        height: 30px;
        top: -30px;
        right: -30px;
        color: $white;
        cursor: pointer;
        &::before, &::after {
          position: absolute;
          display: block;
          content: '';
          position: absolute;
          width: 100%;
          height: 2px;
          top: 50%;
          left: 0;
          background-color: $white;
          transform: rotate(45deg);
          transition: all .2s linear;
        }
        &::after {
          transform: rotate(-45deg);
        }
        &:hover{
          &::after, &::before {
            background-color: $orange;
          }
        }
      }
    }
  }
  &-circles{
    position: absolute;
    top: 90px;
    right: -150px;
    z-index: 2;
    &-one{
      width: 800px;
      height: 800px;
      border-radius: 50%;
      border: 2px dashed rgba($orange,.4);
      animation: circleRotate 120s linear infinite;
    }
    &-two{
      width: 860px;
      height: 860px;
      border-radius: 50%;
      border: 2px dashed rgba($white,.4);
      position: absolute;
      top: -30px;
      left: -30px;
      animation: circleRotate2 120s linear infinite;
    }
  }
  &-order-conf{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: rgba($black,.6);
    &-block{
      @include col;
      width: 580px;
      height: 400px;
      @include sm(width, 95%);
      color: $white;
      position: fixed;
      top: calc(50% - 200px);
      left: calc(50% - 290px);
      @include sm(top, 0);
      @include sm(left, 0);
      @include sm(box-sizing, border-box);
      padding: 32px 77px;
      @include sm(padding, 32px 30px 32px 50px);
      background-color: rgba($black,.8);
      &>div{
        width: 100%;
        height: 100%;
        overflow-y: scroll;
      }
      i{
        position: absolute;
        top: -30px;
        right: -30px;
        @include sm(top, 15px);
        @include sm(right, 15px);
        cursor: pointer;
      }
      h2{
        position: relative;
        font-size: 24px;
        text-transform: none;
        &:before{
          display: block;
          content: '';
          position: absolute;
          top: -20%;
          left: -45px;
          @include sm(left, -25px);
          @include sm(top, 0);
          width: 2px;
          height: 140%;
          background-color: $orange;
        }
      }
      h3{
        margin-top: 15px;
      }
    }
  }
}
.map{
  @include sm(display, none);
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: 100%;
  overflow: hidden;
  .row{
    position: relative;
  }
  &-header{
    flex-basis: 100%;
    margin: 130px 10px 0 10px;
    z-index: 2;
    h1{
      font-size: 2.8rem;
    }
    h1:before{
      @include orange-line;
    }
  }
  &-main{
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 600px;
    background-size: 100%;
    background-repeat: no-repeat;
    z-index: 2;
    &-coordinate{
      position: relative;
      width: 100%;
      height: 100%;
      li{
        position: absolute;
        cursor: pointer;
        p{
          display: inline-block;
          color: white;
        }
        span{
          display: inline-block;
          height: 12px;
          width: 12px;
          background-color: $orange;
          border-radius: 50%;
          position: relative;
          &:after{
            display: block;
            content: '';
            position: absolute;
            width: calc(100% + 6px);
            height: calc(100% + 6px);
            border-radius: 50%;
            top: -3px;
            left: -3px;
            background-color: rgba($orange,.4);
          }
        }
        &:hover span{
          animation: blink 1s linear infinite;
        }
        &.usa{
          top: 235px;
          left: 250px;
        }
        &.france{
          top: 200px;
          left: 550px;
        }
        &.estonia{
          display: none;
          top: 145px;
          left: 625px;
        }
        &.fin{
          display: none;
          top: 120px;
          left: 625px;
        }
        &.rus{
          top: 130px;
          left: 720px;
        }
        &.ukr{
          top: 185px;
          left: 640px;
        }
        &.az{
          display: none;
          top: 220px;
          left: 690px;
        }
        &.kipr{
          display: none;
          top: 240px;
          left: 640px;
        }
        &.oae{
          top: 290px;
          left: 715px;
        }
        &.katar{
          display: none;
          top: 270px;
          left: 700px;
        }
        &.thai{
          display: none;
          top: 310px;
          left: 860px;
        }
      }
    }
  }
  &:after{
    position: absolute;
    display: block;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.7);
    z-index: 1;
  }
  &-info{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($black,.6);
    z-index: 9999;
    &-block{
      position: absolute;
      width: 512px;
      height: 290px;
      top: calc(50% - 145px);
      left: calc(50% - 256px);
      background-color: rgba($black,.9);
      box-sizing: border-box;
      padding: 25px;
      color: $white;
      &-header{
        display: flex;
        justify-content: space-between;
        border-left: 3px solid $orange;
        &-name{
          padding-left: 15px;
          border-bottom: 1px solid $orange;
        }
        &-video{
          z-index: 2;
          img{
            float: left;
            margin-right: 10px;
            cursor: pointer;
          }
          p{
            margin-bottom: 40px;
            float: right;
            width: 100px;
            text-align: center;
          }
        }
      }
      &>p{
        padding: 15px 0;
      }
      &-achievement{
        display: flex;
        justify-content: center;
        &-item{
          width: 78px;
          margin: 0 15px;
          text-align: center;
          span{
            font-size: 24px;
            font-family: 'Lobster', cursive;
            color: $orange;
          }
          p{
            font-size: 12px;
          }
        }
      }
      &:after{
        display: block;
        content: '';
        position: absolute;
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        top: 10px;
        left: 10px;
        outline: 1px solid $orange;
        z-index: 1;
      }
      &-close{
        position: absolute;
        width: 20px;
        height: 20px;
        top: -20px;
        right: -20px;
        cursor: pointer;
        &:before{
          position: absolute;
          display: block;
          content: '';
          width: 100%;
          height: 2px;
          top: 50%;
          left: 0;
          background-color: $white;
          transform: rotate(45deg);
        }
        &:after{
          position: absolute;
          display: block;
          content: '';
          width: 100%;
          height: 2px;
          top: 50%;
          left: 0;
          background-color: $white;
          transform: rotate(-45deg);
        }
      }
    }
  }
}
.gallery{
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-position: center center;
  background-size: 100% 100%;
  @include sm(background-size, cover);
  @include sm(margin-bottom, -40px);
  &-header{
    z-index: 3;
    margin: 130px 15px 0 15px;
    display: flex;
    @include sm(flex-direction, column);
    flex-basis: 100%;
    justify-content: space-between;
    h1{
      font-size: 2.8rem;
      @include sm(font-size, 24px);
    }
    h1:before{
      position: relative;
      @include orange-line;
    }
  }
  &-filters{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @include sm(flex-wrap, wrap);
    @include sm(justify-content, space-between);
    &-item{
      margin-left: 30px;
      @include sm(margin, 15px 0);
      color: white;
      @include sm(flex-basis, 50%);
      figure{
        color: $orange;
        margin: 0;
        padding: 0;
        display: inline-block;
        position: relative;
        i{
          cursor: pointer;
        }
        div{
          display: none;
          position: absolute;
          top: calc(100% + 2px);
          background-color: rgba(0,0,0,.8);
          z-index: 999;
          p{
            cursor: pointer;
            &:hover{
              background-color: $orange;
              color: white;
            }
          }
        }
      }
    }
  }
  &-main{
    z-index: 2;
    width: calc(100% - 15px);
    margin-bottom: 50px;
    &-item{
      width: 25%;
      @include sm(width, 100%);
      height: 150px;
      @include sm(height, 250px);
      overflow: hidden;
      position: relative;
      float: left;
      cursor: pointer;
      img{
        position: absolute;
        top: 15px;
        left: 15px;
        min-width: calc(100% - 30px);
        min-height: calc(100% - 30px);
        width: auto;
        height: auto;
        object-fit: cover;
      }
      &:after{
        position: absolute;
        content: '';
        opacity: 0;
        background-color: rgba($orange, .6);
        top: 15px;
        left: 15px;
        min-width: calc(100% - 15px);
        min-height: calc(100% - 15px);
        transition: 300ms ease;
      }
      i{
        transform: scale(1,1);
        opacity: 0;
        position: absolute;
        color: white;
        top: calc(50% - 1px);
        left: 50%;
        z-index: 2;
        transition: 300ms ease;
      }
      &:hover::after,
      &:hover i{
        opacity: 1;
      }
      &[data-gallery="video"]{
        i{
          opacity: 1;
          left: 130px;
          top: calc(50% - 7px);
          color: $orange;
          border: 1px solid $orange;
          border-radius: 50%;
          padding: 5px;
          width: 27px;
          &:before{
            position: relative;
            left: 5px;
          }
        }
      }
    }
    .gallery-size-2{
      height: 300px;
    }
    .gallery-size-3{
      width: 50%;
      @include sm(width, 100%);
      height: 300px;
    }
  }
  &:after{
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.8);
    z-index: 1;
  }
  &-player{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($black,.6);
    z-index: 9999;
    &-block{
      position: absolute;
      width: 760px;
      height: 480px;
      @include sm(width, 100%);
      @include sm(max-height, 100%);
      top: calc(50% - 240px);
      left: calc(50% - 380px);
      @include sm(top, 0);
      @include sm(left, 0);
      background-color: rgba($black,.9);
      .gallery-content{
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
      }
      &-close{
        position: absolute;
        top: -30px;
        right: -30px;
        @include sm(top, 0);
        @include sm(right, 0);
        width: 30px;
        height: 30px;
        cursor: pointer;
        z-index: 100;
        &:before{
          display: block;
          content: '';
          position: absolute;
          width: 100%;
          height: 2px;
          top: 50%;
          left: 0;
          background-color: $white;
          transform: rotate(45deg);
          transition: all .2s linear;
        }
        &:after{
          display: block;
          content: '';
          position: absolute;
          width: 100%;
          height: 2px;
          top: 50%;
          left: 0;
          background-color: $white;
          transform: rotate(-45deg);
          transition: all .2s linear;
        }
        &:hover {
          &:after, &:before {
            background-color: $orange;
          }
        }
      }
      i{
        position: absolute;
        top: 40%;
        left: -30px;
        color: white;
        cursor: pointer;
        transition: all .2s linear;
        &.fa-angle-right{
          left: auto;
          right: -30px;
        }
        &:hover{
          transform: scale(1.1, 1.1);
        }
      }
      &-image{
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,.1);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
      .galPlayI{
        position: absolute;
        top: calc(50% - 42px);
        left: calc(50% - 33px);
        transform: scale(0.6);
        color: $orange;
        z-index: 999;
        &:hover {
          transform: scale(0.7);
        }
      }
      video{
        width: 100%;
        position: relative;
      }
    }
  }
}
.footer{
  position: fixed;
  @include sm(position, relative);
  @include sm(top, 0px);
  width: 100%;
  height: 40px;
  left: 0;
  bottom: 0;
  background-color: rgba(0,0,0,.2);
  z-index: 999;
  &-text{
    margin: 0;
    margin-top: 10px;
    @include sm(margin, 0);
    @include sm(padding, 0);
    padding-bottom: 15px;
    h4{
      z-index: 3;
      text-transform: none;
    }
  }
}
.burger{
  display: flex;
  position: fixed;
  background-image: url('../img/burger-bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  top: 0;
  right: -800px;
  width: 400px;
  @include sm(width, 100%);
  height: 100vh;
  z-index: 9999;
  box-sizing: border-box;
  padding: 25px 25px 50px 25px;
  flex-direction: column;
  justify-content: space-between;
  font-family: $condFont;
  overflow: hidden;
  &-header{
    @include offset-left(1);
    margin-top: 20px;
    &-item{
      color: $white;
      font-size: 18px;
      @include sm(font-size, 16px);
      margin-bottom: 40px;
      text-transform: uppercase;
      cursor: pointer;
      transition: all .2s linear;
      position: relative;
      &:nth-child(3){
        @include sm(display, none);
      }
      &:after{
        display: block;
        position: absolute;
        content: '';
        width: 0;
        height: 2px;
        background-color: $black;
        top: calc(50% - 1px);
        left: -250px;
        transition: all .2s linear;
      }
      &:hover::after{
        width: 100%;
      }
    }
  }
  &-footer{
    @include offset-left(1);
    color: $black;
    font-family: $condFont;
    &-item{
      font-size: 16px;
      margin: 10px 0;
      i{
        margin-right: 5px;
      }
    }
  }
  &-close {
    position: absolute;
    width: 30px;
    height: 30px;
    top: 20px;
    right: 25px;
    color: $white;
    cursor: pointer;
    &::before, &::after {
      position: absolute;
      display: block;
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      top: 50%;
      left: 0;
      background-color: $black;
      transform: rotate(45deg);
      transition: all .2s linear;
    }
    &::after {
      transform: rotate(-45deg);
    }
    &:hover{
      &::after, &::before {
        background-color: #fff;
      }
    }
  }
}

.burger_subscribe{
  &>p {
    font-size: 18px;
  }
  font-size: 24px;
  @include sm(font-size, 16px);
  text-transform: uppercase;
  font-weight: bold;
  i{
    position: relative;
    margin: 20px 10px 0 0;
    transition: all .2s linear;
    cursor: pointer;
    a{
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    &:hover{
      color: $white;
    }
  }
  &:after{
    display: block;
    content: '';
    width: 100%;
    height: 1px;
    background-color: $black;
    margin: 40px 0;
  }
}
.show-type{
  font-size: .9rem;
  &:after{
    display: block;
    content: '';
    height: 2px;
    width: 250px;
    @include sm(width, 100%);
    background-color: $orange;
    margin: 10px 0;
  }
}
.next-slide-info,
.prev-slide-info{
  @include sm(display, none);
  position: fixed;
  display: flex;
  align-items: center;
  height: 50px;
  width: 25px;
  top: 45%;
  left: 80px;
  opacity: 0;
  transition: all .2s linear;
  z-index: 999;
  h3{
    font-size: 1.1rem;
    color: rgba($orange,.5px);
  }
}
.prev-slide-info{
  &.show{
    left: 45px;
    opacity: 1;
  }
}
.next-slide-info{
  right: 150px;
  left: auto;
  &.show{
    right: 80px;
    opacity: 1;
  }
}
.show-type-title{
  position: absolute;
  color: rgba($orange,.7);
  font-size: 1.1rem;
  top: 2px;
  opacity: 0;
  right: -140px;
  transition: all .2s linear;
  &.active{
    right: 40px;
    opacity: 1;
  }
}

::-webkit-scrollbar-button {
  background-image:url('');
  background-repeat:no-repeat;
  width:5px;
  height:0px
}

::-webkit-scrollbar-track {
  background-color: rgba($black,.8);
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 0px;
  border-radius: 0px;
  background-color: $orange;
}

::-webkit-scrollbar-thumb:hover{
  background-color:#56999f;
}

::-webkit-resizer{
  background-image:url('');
  background-repeat:no-repeat;
  width:4px;
  height:0px
}

::-webkit-scrollbar{
  width: 4px;
}

.gallery-player-block-image__description {
  display: block;
  position: absolute;
  padding: 15px;
  box-sizing: border-box;
  width: 100%;
  bottom: 0;
  background-color: rgba(0,0,0,.6);
  color: white;
}

.gallery__bg {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-position: center center;
  background-size: cover;
  z-index: 0;
}
.gallery__bg::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,.7);
  z-index: 0;
}